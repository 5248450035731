import React from "react"
import { graphql } from "gatsby"
import * as moment from "moment"
import Atlas from "../components/Atlas"
import Button from "../components/Button"
import Container from "../components/Container"
import Section from "../components/Section"
import Site from "../components/Site"
import StickyFilters from "../components/StickyFilters"
import Svg from "../components/Svg"
import { formatLink, formatTime } from "../helpers"
import "../css/events.scss"

const Event = ({data}) => {
  const ttt           = 'YYYY-MM-DDTHH:mm:ss-Z'
  const dataEvent     = data.datoCmsEvent
  const eventIsOneDay = moment(dataEvent.start, ttt).format('YYYY-MM-DD') === moment(dataEvent.end, ttt).format('YYYY-MM-DD')

  return (
    <Site
      title     = {`${dataEvent.title} | ${moment(dataEvent.start, ttt).format('MMMM D, YYYY')}`}
      className = "hcc-events is-single"
    >
      <article style={{ '--c-base-accent' : `var(--c-${dataEvent.ministries[0].slug})` }}>
        <Section tag="header" className="hcc-events-intro">
          <Container>
            <span className="hcc-events-ministry-badges">
              {dataEvent.ministries.map((ministry, i, arr) =>
                <i key={`${dataEvent.id}-${ministry.slug}`} style={{ '--c-base-accent' : `var(--c-${ministry.slug})`, zIndex : arr.length - i }}><span className={i > 0 ? "is-hidden" : null}>{ministry.name}</span></i>
              )}
            </span>
            <h1 className="is-single-title">{dataEvent.title}</h1>
            <span className="is-dateline">
              {formatTime(dataEvent.start, 'eventSingleStart')}
              {eventIsOneDay ? <React.Fragment>&nbsp;-&nbsp;{formatTime(dataEvent.end, 'timeLong')}</React.Fragment> : <React.Fragment>&nbsp;&rarr;&nbsp;{formatTime(dataEvent.end, 'eventEndLong')}</React.Fragment>} / {dataEvent.location.title}
            </span>
          </Container>
        </Section>
        <StickyFilters>
          <Container>
            <Button label={<><Svg type="ctrl-left"/>&nbsp;<span>Events</span></>} href={formatLink('', 'e')} className="is-single-link"/>
            <Button label={<><Svg type="rsvp"/>&nbsp;&nbsp;<span>Register</span></>} href={dataEvent.registrationLink} className="is-single-link" target="_blank" rel="noopener noreferrer"/>
          </Container>
        </StickyFilters>
        <Section className="hcc-events-map">
          <Atlas
            lat         = {dataEvent.location.coordinates.latitude}
            lng         = {dataEvent.location.coordinates.longitude}
            draggable   = {false}
            zoomLevel   = "12"
            zoomControl = {false}
            theme       = "light"
            markerColor = "var(--c-base-accent)"
          />
        </Section>
      </article>
    </Site>
  )
}

export default Event

export const query = graphql`
  query EventQuerySingle($id : String!) {
    datoCmsEvent(id: {eq : $id}) {
      ...EventFragment
    }
  }
`
