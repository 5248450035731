import React from "react"
import PropTypes from "prop-types"
import GoogleMapReact from "google-map-react";
import Frame from "../components/Frame"
import Svg from "../components/Svg"

const Atlas = ({lat, lng, draggable, zoomLevel, zoomControl, showTraffic, showLocalRoadLabels, address, theme, markerSize, markerColor, children, ...props}) => {

  const mapKey          = 'AIzaSyB4S2uuaP1tKcovHrcvWPWVp2L2JZSFuwI' // AIzaSyCu3r4Ua2Q1IYgP6afwQvjpcPjpdabuw1M
  const localRoadLabels = showLocalRoadLabels ? 'on' : 'off'

  const originalMapStyles = [
    { "featureType": "road",               "elementType": "geometry.fill",      "stylers": [{ "color"      : "#ffffff"       }] },
    { "featureType": "road",               "elementType": "geometry.stroke",    "stylers": [{ "visibility" : "off"           }] },
    { "featureType": "road",               "elementType": "labels.text.fill",   "stylers": [{ "color"      : "#404a57"       }] },
    { "featureType": "road",               "elementType": "labels.text.stroke", "stylers": [{ "color"      : "#ffffff"       }] },
    { "featureType": "road.local",         "elementType": "labels",             "stylers": [{ "visibility" : localRoadLabels }] },
    { "featureType": "road.highway",       "elementType": "geometry.fill",      "stylers": [{ "color"      : "#ffffff"       }] },
    { "featureType": "poi",                "elementType": "labels",             "stylers": [{ "visibility" : "off"           }] },
    { "featureType": "poi",                "elementType": "geometry",           "stylers": [{ "color"      : "#ced0d5"       }] },
    { "featureType": "water",                                                   "stylers": [{ "color"      : "#97c2d9"       }] },
    { "featureType": "landscape",                                               "stylers": [{ "color"      : "#e8eaee"       }] },
    { "featureType": "landscape.man_made",                                      "stylers": [{ "visibility" : "off"           }] },
  ]

  const darkMapStyles = [
    { "featureType": "administrative.land_parcel",                                          "stylers": [{ "visibility" : "off"           }] },
    {                                                  "elementType": "geometry",           "stylers": [{ "color"      : "#212121"       }] },
    {                                                  "elementType": "labels.icon",        "stylers": [{ "visibility" : "off"           }] },
    {                                                  "elementType": "labels.text.fill",   "stylers": [{ "color"      : "#757575"       }] },
    {                                                  "elementType": "labels.text.stroke", "stylers": [{ "color"      : "#212121"       }] },
    { "featureType": "administrative",                 "elementType": "geometry",           "stylers": [{ "color"      : "#757575"       }] },
    { "featureType": "administrative.country",         "elementType": "labels.text.fill",   "stylers": [{ "color"      : "#9e9e9e"       }] },
    { "featureType": "administrative.locality",        "elementType": "labels.text.fill",   "stylers": [{ "color"      : "#bdbdbd"       }] },
    { "featureType": "poi",                            "elementType": "labels.text.fill",   "stylers": [{ "color"      : "#757575"       }] },
    { "featureType": "poi",                            "elementType": "labels",             "stylers": [{ "visibility" : "off"           }] },
    { "elementType": "labels.icon",                                                         "stylers": [{ "visibility" : localRoadLabels }] },
    { "featureType": "poi.park",                       "elementType": "geometry",           "stylers": [{ "color"      : "#181818"       }] },
    { "featureType": "poi.park",                       "elementType": "labels.text.fill",   "stylers": [{ "color"      : "#616161"       }] },
    { "featureType": "poi.park",                       "elementType": "labels.text.stroke", "stylers": [{ "color"      : "#1b1b1b"       }] },
    { "featureType": "road",                           "elementType": "geometry.fill",      "stylers": [{ "color"      : "#2c2c2c"       }] },
    { "featureType": "road",                           "elementType": "labels.text.fill",   "stylers": [{ "color"      : "#8a8a8a"       }] },
    { "featureType": "road.arterial",                  "elementType": "geometry",           "stylers": [{ "color"      : "#373737"       }] },
    { "featureType": "road.highway",                   "elementType": "geometry",           "stylers": [{ "color"      : "#3c3c3c"       }] },
    { "featureType": "road.highway.controlled_access", "elementType": "geometry",           "stylers": [{ "color"      : "#4e4e4e"       }] },
    { "featureType": "road.local",                     "elementType": "labels.text.fill",   "stylers": [{ "color"      : "#616161"       }] },
    { "featureType": "transit",                        "elementType": "labels.text.fill",   "stylers": [{ "color"      : "#757575"       }] },
    { "featureType": "water",                          "elementType": "geometry",           "stylers": [{ "color"      : "#000000"       }] },
    { "featureType": "water",                          "elementType": "labels.text.fill",   "stylers": [{ "color"      : "#3d3d3d"       }] },
  ]

  const lightMapStyles = [
    { "featureType": "administrative.land_parcel",                                    "stylers": [{ "visibility" : "off"           }] },
    { "elementType": "geometry",                                                      "stylers": [{ "color"      : "#f5f5f5"       }] },
    { "elementType": "labels.icon",                                                   "stylers": [{ "visibility" : localRoadLabels }] },
    { "elementType": "labels.text.fill",                                              "stylers": [{ "color"      : "#616161"       }] },
    { "elementType": "labels.text.stroke",                                            "stylers": [{ "color"      : "#f5f5f5"       }] },
    { "featureType": "administrative.land_parcel", "elementType": "labels.text.fill", "stylers": [{ "color"      : "#bdbdbd"       }] },
    { "featureType": "poi",                        "elementType": "geometry",         "stylers": [{ "color"      : "#eeeeee"       }] },
    { "featureType": "poi",                        "elementType": "labels.text.fill", "stylers": [{ "color"      : "#757575"       }] },
    { "featureType": "poi",                        "elementType": "labels",           "stylers": [{ "visibility" : "off"           }] },
    { "featureType": "poi.park",                   "elementType": "geometry",         "stylers": [{ "color"      : "#e5e5e5"       }] },
    { "featureType": "poi.park",                   "elementType": "labels.text.fill", "stylers": [{ "color"      : "#9e9e9e"       }] },
    { "featureType": "road",                       "elementType": "geometry",         "stylers": [{ "color"      : "#ffffff"       }] },
    { "featureType": "road.arterial",              "elementType": "labels.text.fill", "stylers": [{ "color"      : "#757575"       }] },
    { "featureType": "road.highway",               "elementType": "geometry",         "stylers": [{ "color"      : "#dadada"       }] },
    { "featureType": "road.highway",               "elementType": "labels.text.fill", "stylers": [{ "color"      : "#616161"       }] },
    { "featureType": "road.local",                 "elementType": "labels.text.fill", "stylers": [{ "color"      : "#9e9e9e"       }] },
    { "featureType": "transit.line",               "elementType": "geometry",         "stylers": [{ "color"      : "#e5e5e5"       }] },
    { "featureType": "transit.station",            "elementType": "geometry",         "stylers": [{ "color"      : "#eeeeee"       }] },
    { "featureType": "water",                      "elementType": "geometry",         "stylers": [{ "color"      : "#c9c9c9"       }] },
    { "featureType": "water",                      "elementType": "labels.text.fill", "stylers": [{ "color"      : "#9e9e9e"       }] },
  ]

  const mapOptions = {
    fullscreenControl : false,
    mapTypeControl    : false,
    panControl        : false,
    rotateControl     : false,
    scaleControl      : false,
    streetViewControl : false,
    zoomControl       : zoomControl,
    scrollwheel       : false,
    draggable         : draggable,
    styles            : theme === 'light' ? lightMapStyles : theme === 'dark' ? darkMapStyles : originalMapStyles,
  }

  const markerStyles = {
    display   : 'block',
    position  : 'relative',
    width     : '1em',
    height    : '1em',
    fontSize  : markerSize,
    textAlign : 'center',
    color     : markerColor,
    transform : 'translate3d(-50%, -100%, 0)',
  }


  // Output
  // ------
  // const apiIsLoaded = (map, maps, places) => {
  //   console.log(map)
  // }
  // 
  // onGoogleApiLoaded   = {({ map, maps, places }) => apiIsLoaded(map, maps, places)}
  // yesIWantToUseGoogleMapApiInternals

  return (
    <div className="hcc-atlas">
      <Frame>
        <GoogleMapReact
          bootstrapURLKeys    = {{ key: mapKey }}
          defaultCenter       = {{ lat, lng }}
          defaultZoom         = {parseInt(zoomLevel)}
          hoverDistance       = {30}
          debounced           = {true}
          resetBoundsOnResize = {true}
          layerTypes          = {showTraffic ? ['TrafficLayer'] : []}
          options             = {mapOptions}
        >
          <AtlasMarker
            lat   = {lat}
            lng   = {lng}
            style = {markerStyles}
          />
        </GoogleMapReact>
      </Frame>
      {address &&
        <div className="hcc-atlas-address">
          {address}
        </div>
      }
      {children}
    </div>
  )
}

Atlas.defaultProps = {
  lat                 : null,
  lng                 : null,
  draggable           : false,
  zoomLevel           : 16,
  zoomControl         : false,
  showTraffic         : false,
  showLocalRoadLabels : true,
  address             : null,
  theme               : 'original',
  markerSize          : '2rem',
  markerColor         : 'var(--c-talks)',
  children            : null,
}

Atlas.propTypes = {
  lat                 : PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lng                 : PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  draggable           : PropTypes.bool,
  zoomLevel           : PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  zoomControl         : PropTypes.bool,
  showTraffic         : PropTypes.bool,
  showLocalRoadLabels : PropTypes.bool,
  address             : PropTypes.object,
  theme               : PropTypes.string,
  markerSize          : PropTypes.string,
  markerColor         : PropTypes.string,
  children            : PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

export default Atlas


const AtlasMarker = ({ ...props }) => {
  return (
    <div lat={props.lat} lng={props.lng} style={props.style}>
      <Svg type="location"/>
    </div>
  )
}
